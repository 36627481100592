﻿@import "constants.scss";

.about-content-section {
  padding-left: 15px;
  max-width: 650px;
  margin: 0 auto;
}

#about-pp-container {
  display: flex;
  justify-content: space-between;
  padding: 0;
  width: 100%;
}

#about-pp-container > div > div {
  text-align: center;
  padding: 8px;
}

#about-pp-container img {
  height: 150px;
  width: 150px;
  border-radius: 75px;
  margin-top: 8px;
}

#about-pp-container hr {
  margin-top: 0px;
  max-width: 80%;
}
#about-content-side hr {
  max-width: 80%;
}
#about-topics {
  clear: both;
}

#about-topics li {
  display: block;
  padding: 0px 5px;
}

@media (max-width: 700px) {
  .leftimg {
    display: block;
    margin: 0 auto 10px auto;
  }
  h3 {
    text-align: center;
  }
}

@media (min-width: 700px) {
  .leftimg {
    display: inline-block;
    vertical-align: middle;
    padding: 0px 20px 20px 0px;
  }

  #about-topics p {
    display: inline-block;
  }
}

#about-topics h4 {
  text-decoration: underline;
}

#about-topics ul {
  list-style-type: none;
  padding: 0px;
}

#about-topics li hr {
  width: 30%;
  clear: both;
}

@media (max-width: 768px) {
  /*Small*/
  .about-content-section {
    padding-left: 0;
  }
  #about-content-side {
    display: block;
  }
  #about-content-side hr {
    display: none;
  }
  #about-pp-container {
    flex-direction: column;
  }
  #about-pp-container > div > div {
    float: left;
    margin-right: 10px;
  }
  #about-pp-container hr {
    display: none;
  }
}

@media (min-width: 1000px) {
  /*Big*/
  #about-content-side {
    float: right;
    top: 0px;
    height: 100%;
    max-width: 25%;
    margin: 0px 15px;
  }
  #about-content-side p {
    text-align: justify;
  }
  .fancy-text {
    font-size: 32pt;
  }
  #about-pp-container {
    flex-direction: row;
  }
  #about-pp-container > div {
    width: 33%;
  }
}

#map-container {
  border: 1px black $backgroundColor;
}

.about-two-panel {
  padding: 0 8px;
  max-width: 300px;
}

.about-content-section .flex-container-lg {
  max-width: 650px;
  margin: 0 auto;
}

#about-contact-info {
  min-width: 280px;
}
