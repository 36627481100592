body {
  background-color: #141414;
}

footer {
  width: 308px;
  color: #dfdfdf;
  margin: 16px auto;
}

hr {
  border-top: 1px solid #000;
  border-bottom: 1px solid #ffffff1a;
}

h2 {
  margin: 20px;
}

p {
  font-size: 14pt;
}

input, select, textarea {
  max-width: 280px;
}

.background-color {
  background-color: #262626;
}

.body-container {
  color: #dfdfdf;
  max-width: 990px;
  border: 2px solid #222;
  margin-top: 15px;
  padding-left: 0;
  padding-right: 0;
  font-family: Cambria Math, Tahoma, Geneva, Verdana, sans-serif;
}

.body-content {
  width: 100%;
  padding: 0 10px;
}

.body-content a {
  color: #0b890f;
}

.body-content a:hover {
  color: #ccc;
}

.body-content h2 {
  text-align: center;
}

@media (max-width: 768px) {
  .flex-container-lg {
    flex-direction: column;
  }
}

@media (min-width: 768px) {
  .flex-reverse-lg {
    flex-direction: row-reverse;
  }
}

.flex-container-lg, .flex-container {
  justify-content: space-around;
  display: flex;
}

.flex-center-vert {
  align-items: center;
}

#home-categories-container .title {
  text-align: center;
  padding: 5px;
}

#footer-bar {
  clear: both;
  width: 80%;
}

.dl-horizontal dt {
  white-space: normal;
}

@media (min-width: 768px) {
  .visible-mobile-only {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .body-container {
    min-height: 800px;
    border: none;
    margin: 0;
  }

  .navbar {
    margin-bottom: 0;
  }
}

.navbar-toggle {
  margin-top: 12px;
}

.navbar-header {
  min-height: 64px;
}

.navbar-inverse {
  border-bottom: 1px solid;
  box-shadow: inset 0 -1px #000;
  border-color: #ffffff1a !important;
}

.navbar-inverse .navbar-nav > li > a {
  color: #dfdfdf;
}

.navbar-brand {
  padding: 0 !important;
}

.navbar-brand img {
  height: 48px;
  margin: 8px 5px;
}

.navbar-collapse > ul {
  margin: 5px;
}

#banner-container {
  padding-bottom: 15px;
  position: relative;
}

@media (max-width: 768px) {
  #banner-container img {
    width: 100%;
  }

  #banner-container {
    width: 100%;
    overflow: hidden;
  }
}

@media (min-width: 768px) {
  #banner-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow-y: hidden;
  }

  #banner-container img {
    max-width: 100%;
    max-height: 600px;
    margin: 0 auto;
    display: block;
  }
}

#home-logo-info-container-right {
  max-width: 400px;
  margin: 25px auto 0;
}

@media (min-width: 1225px) {
  #home-logo-info-container {
    width: 100%;
    height: 136px;
  }

  #home-logo-info-container-left {
    float: left;
  }

  #home-logo-info-container-right {
    min-width: 284px;
    margin: 35px 85px 0 0;
  }
}

#home-categories-container {
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  padding: 10px;
  font-size: 14pt;
  display: flex;
}

#home-categories-container .category {
  background-color: #161616;
  margin: 10px;
  box-shadow: 0 4px 8px #0003, 0 6px 20px #00000030;
}

@media (max-width: 768px) {
  #home-categories-container .category {
    max-width: 294px;
    flex-direction: column;
  }

  #home-categories-container img {
    max-width: 290px;
  }
}

@media (min-width: 768px) and (max-width: 1225px) {
  #home-categories-container img {
    max-height: 140px;
  }
}

@media (min-width: 768px) {
  #home-categories-container {
    flex-direction: row;
    margin: auto;
  }

  #home-row-centerfix {
    padding-left: 75px;
  }
}

@media (min-width: 1225px) {
  #home-categories-container img {
    max-height: 200px;
    display: block;
  }
}

#home-categories-container > div > div {
  text-align: center;
  margin: 15px 0;
}

#home-categories-container a {
  color: #eee;
}

.home-categories-title {
  text-align: center;
  width: 100%;
  background-color: #409742;
  border-radius: 5px;
  padding: 8px;
}

#home-categories-container .category:hover {
  background-color: #0b890f;
}

#home-intro-logo {
  max-width: 680px;
  margin: 20px auto 0;
  padding: 5px;
}

#home-intro-logo img {
  max-width: 100%;
  padding: 5px 30px 10px;
}

@media (max-width: 768px) {
  #home-intro-logo {
    margin: auto;
    float: none !important;
  }

  #home-intro-logo img {
    padding: 0 0 10px;
  }
}

@media (min-width: 768px) {
  .lead {
    max-width: 68%;
  }
}

#home-intro-links-container {
  width: 60%;
  margin: 0 auto;
}

#in-the-news {
  padding: 10px;
}

#in-the-news h2 {
  clear: both;
  padding-top: 10px;
}

.headline {
  color: #222;
  text-decoration: underline;
}

.headline-inverse {
  text-decoration: underline;
  color: #eee !important;
}

.headline-inverse:hover {
  color: #fff;
}

.lead {
  clear: both;
  padding: 10px;
}

@media (max-width: 550px) {
  #in-the-news img {
    margin: 10px auto;
    display: block;
    float: none !important;
  }
}

.gallery-container {
  background-color: #222;
}

#myCarousel {
  max-width: 400px;
  min-width: 300px;
}

@media (min-width: 768px) {
  #myCarousel {
    float: left;
    margin: 10px 20px 60px 10px;
  }
}

@media (max-width: 768px) {
  #myCarousel {
    margin: auto;
    display: block;
  }
}

.gallery-carousel {
  max-width: 788px;
  margin: 0 auto 30px;
}

.carousel-indicators {
  border-radius: 15px;
  padding-left: 10px;
  padding-right: 10px;
  bottom: -35px;
}

.gallery-list {
  max-width: 900px;
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
}

.gallery-list li {
  float: left;
  max-width: 300px;
}

@media (max-width: 700px) {
  .gallery-list li {
    margin: 20px auto;
    float: none !important;
  }

  .gallery-list a {
    margin: auto;
  }
}

@media (min-width: 768px) {
  .gallery-list li {
    height: 250px;
  }
}

.gallery-link {
  max-width: 300px;
  border: 1px solid #222;
  border-radius: 5px;
  margin: 15px;
  display: block;
  position: relative;
}

.gallery-link img {
  width: 100%;
  border-radius: 5px;
}

.gallery-link-info {
  width: 100%;
  height: 50px;
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#00404040", endColorstr= "#101010", GradientType= 0);
  background: linear-gradient(#40404000 0%, #272727 52%, #101010 100%);
  border-radius: 5px;
  padding-top: 25px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.carousel-indicators li {
  border-color: #dfdfdf !important;
}

.carousel-indicators active {
  background-color: #dfdfdf !important;
}

.gallery-link-info > div {
  color: #ddd;
  font-weight: bold;
}

.gallery-link-info-desc {
  float: left;
  margin-left: 5px;
}

.gallery-link-info-count {
  float: right;
  margin-right: 5px;
}

.gallery-navigation a {
  color: #dfdfdf;
  cursor: pointer;
  background-color: #222;
  border-radius: 5px;
  margin: 10px;
  padding: 5px;
}

.gallery-navigation {
  max-width: 365px;
  justify-content: space-between;
  margin: 15px auto 0;
  display: flex;
}

.gallery-description {
  text-align: center;
  margin: 0;
  padding: 10px;
}

.gallery-description-2 {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 10px;
}

.carousel-inner > .item > img {
  margin: 0 auto;
}

#portfolio-gallery {
  flex-flow: wrap;
  display: flex;
}

#portfolio-gallery a {
  max-width: 50%;
  justify-content: flex-center;
  flex-grow: 1;
  flex-basis: 45%;
  padding: 16px;
}

#portfolio-gallery img {
  width: 100%;
}

.about-content-section {
  max-width: 650px;
  margin: 0 auto;
  padding-left: 15px;
}

#about-pp-container {
  width: 100%;
  justify-content: space-between;
  padding: 0;
  display: flex;
}

#about-pp-container > div > div {
  text-align: center;
  padding: 8px;
}

#about-pp-container img {
  height: 150px;
  width: 150px;
  border-radius: 75px;
  margin-top: 8px;
}

#about-pp-container hr {
  max-width: 80%;
  margin-top: 0;
}

#about-content-side hr {
  max-width: 80%;
}

#about-topics {
  clear: both;
}

#about-topics li {
  padding: 0 5px;
  display: block;
}

@media (max-width: 700px) {
  .leftimg {
    margin: 0 auto 10px;
    display: block;
  }

  h3 {
    text-align: center;
  }
}

@media (min-width: 700px) {
  .leftimg {
    vertical-align: middle;
    padding: 0 20px 20px 0;
    display: inline-block;
  }

  #about-topics p {
    display: inline-block;
  }
}

#about-topics h4 {
  text-decoration: underline;
}

#about-topics ul {
  padding: 0;
  list-style-type: none;
}

#about-topics li hr {
  width: 30%;
  clear: both;
}

@media (max-width: 768px) {
  .about-content-section {
    padding-left: 0;
  }

  #about-content-side {
    display: block;
  }

  #about-content-side hr {
    display: none;
  }

  #about-pp-container {
    flex-direction: column;
  }

  #about-pp-container > div > div {
    float: left;
    margin-right: 10px;
  }

  #about-pp-container hr {
    display: none;
  }
}

@media (min-width: 1000px) {
  #about-content-side {
    float: right;
    height: 100%;
    max-width: 25%;
    margin: 0 15px;
    top: 0;
  }

  #about-content-side p {
    text-align: justify;
  }

  .fancy-text {
    font-size: 32pt;
  }

  #about-pp-container {
    flex-direction: row;
  }

  #about-pp-container > div {
    width: 33%;
  }
}

#map-container {
  border: 1px black #141414;
}

.about-two-panel {
  max-width: 300px;
  padding: 0 8px;
}

.about-content-section .flex-container-lg {
  max-width: 650px;
  margin: 0 auto;
}

#about-contact-info {
  min-width: 280px;
}

.crossfade-container ul {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  list-style: none;
  position: relative;
}

.crossfade-container li, .crossfade-container img {
  width: 100%;
}

.crossfade-container li:not(:first-child) {
  position: absolute;
  top: 0;
  left: 0;
}

.crossfade-container li:nth-child(4) {
  animation: 24s infinite xfade;
}

.crossfade-container li:nth-child(3) {
  animation: 24s 6s infinite xfade;
}

.crossfade-container li:nth-child(2) {
  animation: 24s 12s infinite xfade;
}

.crossfade-container li:first-child {
  animation: 24s 18s infinite xfade;
}

@keyframes xfade {
  17% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  92% {
    opacity: 0;
  }
}

/*# sourceMappingURL=about.7d67c58a.css.map */
