﻿@import "constants.scss";

.gallery-container {
  background-color: $bgColor3;
}

#myCarousel {
  max-width: 400px;
  min-width: 300px;
}

@media (min-width: 768px) {
  #myCarousel {
    float: left;
    margin: 10px 20px 60px 10px;
  }
}

@media (max-width: 768px) {
  #myCarousel {
    display: block;
    margin: auto;
  }
}

.gallery-carousel {
  max-width: 788px;
  margin: 0 auto 30px auto;
}

.carousel-indicators {
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 15px;
  bottom: -35px;
}

.gallery-list {
  max-width: 900px;
  padding: 0px;
  margin: 0px auto;
  list-style-type: none;
}

.gallery-list li {
  float: left;
  max-width: 300px;
}

@media (max-width: 700px) {
  .gallery-list li {
    float: none !important;
    margin: 20px auto;
  }
  .gallery-list a {
    margin: auto;
  }
}

@media (min-width: 768px) {
  .gallery-list li {
    height: 250px;
  }
}

.gallery-link {
  display: block;

  margin: 15px;
  max-width: 300px;
  position: relative;
  border: 1px solid #222;
  border-radius: 5px;
}

.gallery-link img {
  width: 100%;
  border-radius: 5px;
}

.gallery-link-info {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 50px;
  padding-top: 25px;
  border-radius: 5px;

  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#404040+0,101010+100&0+0,1+52,1+100 */
  background: -moz-linear-gradient(
    top,
    rgba(64, 64, 64, 0) 0%,
    rgba(39, 39, 39, 1) 52%,
    rgba(16, 16, 16, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(64, 64, 64, 0) 0%,
    rgba(39, 39, 39, 1) 52%,
    rgba(16, 16, 16, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(64, 64, 64, 0) 0%,
    rgba(39, 39, 39, 1) 52%,
    rgba(16, 16, 16, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00404040', endColorstr='#101010',GradientType=0 ); /* IE6-9 */
}

.carousel-indicators li {
  border-color: $fontColor !important;
}

.carousel-indicators active {
  background-color: $fontColor !important;
}

.gallery-link-info > div {
  color: #ddd;
  font-weight: bold;
}

.gallery-link-info-desc {
  float: left;
  margin-left: 5px;
}

.gallery-link-info-count {
  float: right;
  margin-right: 5px;
}

.gallery-navigation a {
  margin: 10px;
  padding: 5px;
  background-color: $bgColor3;
  color: $fontColor;
  border-radius: 5px;
  cursor: pointer;
}

.gallery-navigation {
  max-width: 365px;
  margin: 15px auto 0px;
  display: flex;
  justify-content: space-between;
}

.gallery-description {
  padding: 10px;
  text-align: center;
  margin: 0px;
}

.gallery-description-2 {
  padding: 10px;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.carousel-inner > .item > img {
  margin: 0 auto;
}

#portfolio-gallery {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#portfolio-gallery a {
  flex-basis: 45%;
  padding: 16px;
  flex-grow: 1;
  max-width: 50%;
  justify-content: flex-center;
}

#portfolio-gallery img {
  width:100%;
}