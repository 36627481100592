﻿$fade-time: 24s;
$fade-time1: 6s;
$fade-time2: 12s;
$fade-time3: 18s;
// see https://codepen.io/adamg511/pen/DooJvx

.crossfade-container {
  ul {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    position: relative;
    width:100%;
  }
  li{
    width:100%;
  }
  img {
    width:100%;
  }
  li:not(:nth-child(1)) {
    position: absolute;
    top: 0;
    left: 0;

    /* left: 50%;
 /* margin-left: -247px;*/
    /* margin-top: 5%;*/
  }
  li:nth-child(4) {
    animation: xfade $fade-time 0s infinite;
  }
  li:nth-child(3) {
    animation: xfade $fade-time $fade-time1 infinite;
  }
  li:nth-child(2) {
    animation: xfade $fade-time $fade-time2 infinite;
  }
  li:nth-child(1) {
    animation: xfade $fade-time $fade-time3 infinite;
  }
  
  @keyframes xfade {
    17% {
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
    92% {
      opacity:0;
    }
  }
}
