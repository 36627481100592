@import "constants.scss";

body {
  background-color: $backgroundColor;
}

footer {
  width: 308px;
  margin: 16px auto 16px auto;
  color: $fontColor;
}

hr {
  border-top: 1px solid #000;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

h2 {
  margin: 20px;
}

p {
  font-size: 14pt;
}

/* Set width on the form input elements since they're 100% wide by default */
input,
select,
textarea {
  max-width: 280px;
}

.background-color {
  background-color: $backgroundColorLight;
}

.body-container {
  font-family: "Cambria Math", Tahoma, Geneva, Verdana, sans-serif;
  color: $fontColor;
  margin-top: 15px;
  padding-left: 0;
  padding-right: 0;
  border: 2px #222 solid;
  max-width: 990px;
}

.body-content {
  width: 100%;
  padding: 0px 10px;

  a {
    color: $linkColor;
  }

  a:hover {
    color: #cccccc;
  }

  h2 {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .flex-container-lg {
    flex-direction: column;
  }
}

@media (min-width: 768px) {
  .flex-reverse-lg {
    flex-direction: row-reverse;
  }
}

.flex-container-lg {
  display: flex;
  justify-content: space-around;
}

.flex-container {
  display: flex;
  justify-content: space-around;
}

.flex-center-vert {
  align-items: center;
}

#home-categories-container .title {
  padding: 5px;
  text-align: center;
}

#footer-bar {
  clear: both;
  width: 80%;
}

/* Override the default bootstrap behavior where horizontal description lists 
   will truncate terms that are too long to fit in the left column 
*/
.dl-horizontal dt {
  white-space: normal;
}

@media (min-width: 768px) {
  .visible-mobile-only {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .body-container {
    border: none;
    margin: 0 0 0 0;
    min-height: 800px;
  }

  .navbar {
    margin-bottom: 0px;
  }
}

.navbar-toggle {
  margin-top:12px;
}

.navbar-header {
  min-height: 64px;
}

.navbar-inverse {
  border-color: rgba(255, 255, 255, 0.1) !important;
  border-bottom: 1px solid;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 1);

  .navbar-nav > li > a {
    color: $fontColor;
  }
}

.navbar-brand {
  padding: 0px !important;

  img {
    height: 48px;
    margin: 8px 5px;
  }
}
.navbar-collapse > ul {
  margin: 5px;
}

#banner-container {
  position: relative;
  padding-bottom: 15px;
}

@media (max-width: 768px) {
  #banner-container img {
    width: 100%;
  }

  #banner-container {
    overflow: hidden;
    width: 100%;
  }
}
@media (min-width: 768px) {
  #banner-container {
    overflow-y: hidden;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }

  #banner-container img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: 600px;
  }
}

#home-logo-info-container-right {
  max-width: 400px;
  margin: 25px auto 0;
}

@media (min-width: 1225px) {
  #home-logo-info-container {
    width: 100%;
    height: 136px;
  }

  #home-logo-info-container-left {
    float: left;
  }

  #home-logo-info-container-right {
    margin: 35px 85px 0px 0px;
    min-width: 284px;
  }
}

#home-categories-container {
  padding: 10px;
  margin: auto;
  font-size: 14pt;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

#home-categories-container .category {
  background-color: #161616;
  margin: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@media (max-width: 768px) {
  #home-categories-container .category {
    flex-direction: column;
    max-width: 294px;
  }
  #home-categories-container img {
    max-width: 290px;
  }
}
@media (min-width: 768px) and (max-width: 1225px) {
  #home-categories-container img {
    max-height: 140px;
  }
}
@media (min-width: 768px) {
  #home-categories-container {
    flex-direction: row;
    margin: auto;
  }

  #home-row-centerfix {
    padding-left: 75px;
  }
}
@media (min-width: 1225px) {
  #home-categories-container img {
    display: block;
    max-height: 200px;
  }
}

#home-categories-container > div > div {
  margin: 15px 0px;
  text-align: center;
}

#home-categories-container a {
  color: #eee;
}

.home-categories-title {
  background-color: #409742;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  padding: 8px;
}

#home-categories-container .category:hover {
  background-color: #0b890f;
}

#home-intro-logo {
  max-width: 680px;
  padding: 5px;
  margin: 20px auto 0px auto;

  img {
    padding: 5px 30px 10px 30px;
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  #home-intro-logo {
    float: none !important;
    margin: auto;

    img {
      padding: 0 0 10px 0;
    }
  }
}
@media (min-width: 768px) {
  .lead {
    max-width: 68%;
  }
}

#home-intro-links-container {
  width: 60%;
  margin: 0 auto;
}

#in-the-news {
  padding: 10px;

  h2 {
    clear: both;
    padding-top: 10px;
  }
}

.headline {
  color: #222222;
  text-decoration: underline;
}

.headline-inverse {
  color: #eee !important;
  text-decoration: underline;
}

.headline-inverse:hover {
  color: #ffffff;
}

.lead {
  padding: 10px;
  clear: both;
}

@media (max-width: 550px) {
  #in-the-news img {
    display: block;
    margin: 10px auto;
    float: none !important;
  }
}
